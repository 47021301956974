@charset "UTF-8";
/************************ECRANS*/
/*=============================================================================
    MIXINS MEDIA QUERIES
 ============================================================================== */
@font-face {
  font-family: "fontello";
  src: url("../font/Splits-icons/splits-icon_1.eot?56425159");
  src: url("../font/Splits-icons/splits-icon_1.eot?56425159#iefix") format("embedded-opentype"), url("../font/Splits-icons/splits-icon_1.woff2?56425159") format("woff2"), url("../font/Splits-icons/splits-icon_1.woff?56425159") format("woff"), url("../font/Splits-icons/splits-icon_1.ttf?56425159") format("truetype"), url("../font/Splits-icons/splits-icon_1.svg?56425159#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "extra-icons-splits";
  src: url("../font/Splits-icons/extra-icons-splits.eot?76831142");
  src: url("../font/Splits-icons/extra-icons-splits.eot?76831142#iefix") format("embedded-opentype"), url("../font/Splits-icons/extra-icons-splits.woff2?76831142") format("woff2"), url("../font/Splits-icons/extra-icons-splits.woff?76831142") format("woff"), url("../font/Splits-icons/extra-icons-splits.ttf?76831142") format("truetype"), url("../font/Splits-icons/extra-icons-splits.svg?76831142#extra-icons-splits") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?56425159#fontello') format('svg');
  }
}
*/
[class^=icon-splits]:before,
[class*=" icon-splits"]:before {
  font-family: "fontello";
}

[class^=icon-extra-splits]:before,
[class*=" icon-extra-splits"]:before {
  font-family: "extra-icons-splits";
}

[class^=icon-splits]:before,
[class*=" icon-splits"]:before,
[class^=icon-extra-splits]:before,
[class*=" icon-extra-splits"]:before {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.1em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-splits_archives:before {
  content: "\e800";
}

/* '' */
.icon-splits_autonomy:before {
  content: "\e802";
}

/* '' */
.icon-splits_back:before {
  content: "\e803";
}

/* '' */
.icon-splits_no-polls:before {
  content: "\e804";
}

/* '' */
.icon-splits_polls:before {
  content: "\e805";
}

/* '' */
.icon-splits_burger:before {
  content: "\e806";
}

/* '' */
.icon-splits_calendar:before {
  content: "\e807";
}

/* '' */
.icon-splits_challenge:before {
  content: "\e808";
}

/* '' */
.icon-splits_chat:before {
  content: "\e809";
}

/* '' */
.icon-splits_check:before {
  content: "\e80a";
}

/* '' */
.icon-splits_checked:before {
  content: "\e80b";
}

/* '' */
.icon-splits_chrono:before {
  content: "\e80c";
}

/* '' */
.icon-splits_comments:before {
  content: "\e80f";
}

/* '' */
.icon-splits_runners:before {
  content: "\e810";
}

/* '' */
.icon-splits_race:before {
  content: "\e811";
}

/* '' */
.icon-splits_circle-clear:before {
  content: "\e812";
}

/* '' */
.icon-splits_elevation:before {
  content: "\e813";
}

/* '' */
.icon-splits_finish:before {
  content: "\e814";
}

/* '' */
.icon-splits_discipline:before {
  content: "\e815";
}

/* '' */
.icon-splits_distance-traveled:before {
  content: "\e816";
}

/* '' */
.icon-splits_proximity:before {
  content: "\e817";
}

/* '' */
.icon-splits_download:before {
  content: "\e818";
}

/* '' */
.icon-splits_drink:before {
  content: "\e819";
}

/* '' */
.icon-splits_register:before {
  content: "\e81a";
}

/* '' */
.icon-splits_edit:before {
  content: "\e81b";
}

/* '' */
.icon-splits_euro:before {
  content: "\e81c";
}

/* '' */
.icon-splits_eye:before {
  content: "\e81d";
}

/* '' */
.icon-splits_forward:before {
  content: "\e81e";
}

/* '' */
.icon-splits_timer:before {
  content: "\e81f";
}

/* '' */
.icon-splits_info-circle:before {
  content: "\e820";
}

/* '' */
.icon-splits_info:before {
  content: "\e821";
}

/* '' */
.icon-splits_shoe:before {
  content: "\e822";
}

/* '' */
.icon-splits_distance:before {
  content: "\e822";
}

/* '' */
.icon-splits_like-bold:before {
  content: "\e823";
}

/* '' */
.icon-splits_like-light:before {
  content: "\e824";
}

/* '' */
.icon-splits_message:before {
  content: "\e825";
}

/* '' */
.icon-splits_sparkling-plot:before {
  content: "\e826";
}

/* '' */
.icon-splits_notification:before {
  content: "\e827";
}

/* '' */
.icon-splits_moon:before {
  content: "\e828";
}

/* '' */
.icon-splits_route:before {
  content: "\e82a";
}

/* '' */
.icon-splits_pharmacy:before {
  content: "\e82b";
}

/* '' */
.icon-splits_picture:before {
  content: "\e82c";
}

/* '' */
.icon-splits_locality:before {
  content: "\e82d";
}

/* '' */
.icon-splits_start:before {
  content: "\e82d";
}

/* '' */
.icon-splits_pin:before {
  content: "\e82d";
}

/* '' */
.icon-splits_poi:before {
  content: "\e82d";
}

/* '' */
.icon-splits_point:before {
  content: "\e82e";
}

/* '' */
.icon-splits_power:before {
  content: "\e82f";
}

/* '' */
.icon-splits_publish:before {
  content: "\e830";
}

/* '' */
.icon-splits_settings:before {
  content: "\e831";
}

/* '' */
.icon-splits_reload:before {
  content: "\e832";
}

/* '' */
.icon-splits_reload-thin:before {
  content: "\e833";
}

/* '' */
.icon-splits_roadbook:before {
  content: "\e834";
}

/* '' */
.icon-splits_search:before {
  content: "\e835";
}

/* '' */
.icon-splits_share:before {
  content: "\e836";
}

/* '' */
.icon-splits_splits-alone:before {
  content: "\e837";
}

/* '' */
.icon-splits_splits-initial:before {
  content: "\e838";
}

/* '' */
.icon-splits_splits-symbol:before {
  content: "\e839";
}

/* '' */
.icon-splits_stars-0:before {
  content: "\e83a";
}

/* '' */
.icon-splits_stars-1:before {
  content: "\e83b";
}

/* '' */
.icon-splits_stars-2:before {
  content: "\e83c";
}

/* '' */
.icon-splits_stars-3:before {
  content: "\e83d";
}

/* '' */
.icon-splits_stars-4:before {
  content: "\e83e";
}

/* '' */
.icon-splits_stars-5:before {
  content: "\e83f";
}

/* '' */
.icon-splits_terrain:before {
  content: "\e840";
}

/* '' */
.icon-splits_trash:before {
  content: "\e841";
}

/* '' */
.icon-splits_unchecked:before {
  content: "\e842";
}

/* '' */
.icon-splits_user:before {
  content: "\e843";
}

/* '' */
.icon-splits_tag:before {
  content: "\e844";
}

/* '' */
.icon-splits_velocity:before {
  content: "\e845";
}

/* '' */
.icon-splits_chevron-down:before {
  content: "\e846";
}

/* '' */
.icon-splits_clear:before {
  content: "\e847";
}

/* '' */
.icon-splits_clear-light:before {
  content: "\e848";
}

/* '' */
.icon-splits_add:before {
  content: "\e849";
}

/* '' */
.icon-picto_utmb:before {
  content: "\e800";
}

.icon-picto-babass:before {
  content: "\e802";
}

/* '' */
.icon-danger:before {
  content: "\e800";
}

/* '' */
.icon-denivele-down:before {
  content: "\e801";
}

/* '' */
.splits-icon-sm {
  font-size: 1rem;
}

.splits-icon-md {
  font-size: 1.5rem;
}

.splits-icon-l {
  font-size: 2rem;
}

.splits-icon-xl {
  font-size: 3rem;
}

.splits-icon-xxl {
  font-size: 3.8rem;
}

.material-icons {
  margin-left: 0.1em !important;
  margin-right: 0.1em !important;
}

.__icon {
  width: 100px;
  height: 100px;
  border: 2px solid #CBD4E5;
  border-radius: 50%;
  background-position: center !important;
  background-size: 80% 80% !important;
}
.__icon._green_border {
  border-color: #0AD6AF;
}
.__icon._center {
  margin: 0 auto;
}
.__icon._no-border {
  border: none;
}
.__icon._bg-size-85 {
  background-size: 85% 85% !important;
}
.__icon._bg-size-90 {
  background-size: 90% 90% !important;
}
.__icon._bg-size-95 {
  background-size: 95% 95% !important;
}
.__icon._bg-size-100 {
  background-size: 100% 100% !important;
}
.__icon._md {
  width: 80px;
  height: 80px;
}
.__icon._sm {
  width: 60px;
  height: 60px;
}
.__icon._sm2 {
  width: 50px;
  height: 50px;
}
.__icon._xs {
  width: 40px;
  height: 40px;
}
.__icon._xxs {
  width: 30px;
  height: 30px;
}
.__icon._marge {
  margin: 0 5px;
}
@media all and (max-width: 1281px) {
  .__icon {
    width: 80px;
    height: 80px;
  }
  .__icon._md {
    width: 60px;
    height: 60px;
  }
  .__icon._sm {
    width: 40px;
    height: 40px;
  }
  .__icon._xs {
    width: 30px;
    height: 30px;
  }
}
@media all and (max-width: 992px) {
  .__icon {
    width: 70px;
    height: 70px;
  }
  .__icon._md {
    width: 50px;
    height: 50px;
  }
  .__icon._sm {
    width: 40px;
    height: 40px;
  }
  .__icon._xs {
    width: 30px;
    height: 30px;
  }
}
@media all and (max-width: 600px) {
  .__icon {
    width: 60px;
    height: 60px;
  }
  .__icon._md {
    width: 40px;
    height: 40px;
  }
  .__icon._sm {
    width: 40px;
    height: 40px;
  }
  .__icon._xs {
    width: 30px;
    height: 30px;
  }
}
.__icon._icon-sea {
  background: url("../icons/_icon-sea.svg") no-repeat;
}
.__icon._icon-mountain {
  background: url("../icons/_icon-mountain.svg") no-repeat;
}
.__icon._icon-beer-lover {
  background: url("../icons/_icon-beer-lover.svg") no-repeat;
}
.__icon._icon-night {
  background: url("../icons/_icon-night.svg") no-repeat;
}
.__icon._icon-castle {
  background: url("../icons/_icon-castle.svg") no-repeat;
}
.__icon._icon-island {
  background: url("../icons/_icon-island.svg") no-repeat;
}
.__icon._icon-charity {
  background: url("../icons/_icon-charity.svg") no-repeat;
}
.__icon._icon-skyrunning {
  background: url("../icons/_icon-skyrunning.svg") no-repeat;
}
.__icon._icon-carnaval {
  background: url("../icons/_icon-carnaval.svg") no-repeat;
}
.__icon._icon-volcano-lover {
  background: url("../icons/_icon-volcano-lover.svg") no-repeat;
}
.__icon._icon-relay {
  background: url("../icons/_icon-relay.svg") no-repeat;
}
.__icon._icon-planet-lover {
  background: url("../icons/_icon-planet-lover.svg") no-repeat;
}
.__icon._icon-ambiance {
  background: url("../icons/_icon-ambiance.svg") no-repeat;
}
.__icon._icon-total-autonomy {
  background: url("../icons/_icon-total-autonomy.svg") no-repeat;
}
.__icon._icon-urban {
  background: url("../icons/_icon-urban.svg") no-repeat;
}
.__icon._icon-city-lover {
  background: url("../icons/_icon-city-lover.svg") no-repeat;
}
.__icon._icon-dplus-lover {
  background: url("../icons/_icon-dplus-lover.svg") no-repeat;
}
.__icon._icon-white {
  background: url("../icons/_icon-white.svg") no-repeat;
}
.__icon._icon-xmas-lover {
  background: url("../icons/_icon-xmas-lover.svg") no-repeat;
}
.__icon._icon-duo {
  background: url("../icons/_icon-duo.svg") no-repeat;
}
.__icon._icon-team {
  background: url("../icons/_icon-team.svg") no-repeat;
}
.__icon._icon-forest-lover {
  background: url("../icons/_icon-forest-lover.svg") no-repeat;
}
.__icon._icon-history {
  background: url("../icons/_icon-history.svg") no-repeat;
}
.__icon._icon-monument {
  background: url("../icons/_icon-monument.svg") no-repeat;
}
.__icon._icon-woman-only {
  background: url("../icons/_icon-woman-only.svg") no-repeat;
}
.__icon._icon-vinyard {
  background: url("../icons/_icon-vinyard.svg") no-repeat;
}
.__icon._icon-gastronomic {
  background: url("../icons/_icon-gastronomic.svg") no-repeat;
}
.__icon._icon-sand {
  background: url("../icons/_icon-sand.svg") no-repeat;
}
.__icon._icon-lake {
  background: url("../icons/_icon-lake.svg") no-repeat;
}
.__icon._icon-poi-void {
  background: url("../icons/chart/nc_Picto_Roadbook_Void_green.svg") no-repeat;
}
.__icon._icon-poi-food {
  background: url("../icons/chart/nc_Picto_Roadbook_FoodAndBeverage_green.svg") no-repeat;
}
.__icon._icon-poi-beverage {
  background: url("../icons/chart/nc_Picto_Roadbook_Beverage_green.svg") no-repeat;
}
.__icon._icon-poi-checkpoint {
  background: url("../icons/chart/nc_Picto_Roadbook_Checkpoint_green.svg") no-repeat;
}
.__icon._icon-poi-time-limit {
  background: url("../icons/chart/nc_Picto_Roadbook_TimeLimit_green.svg") no-repeat;
}
.__icon._icon-poi-life-house {
  background: url("../icons/chart/nc_Picto_Roadbook_LifeHouse_green.svg") no-repeat;
}
.__icon._icon-poi-shuttle {
  background: url("../icons/chart/nc_Picto_Roadbook_Navette_green.svg") no-repeat;
}
.__icon._icon-poi-companion {
  background: url("../icons/chart/nc_Picto_Roadbook_Accompagnant_green.svg") no-repeat;
}
.__icon._icon-poi-finish {
  background: url("../icons/chart/nc_Picto_Roadbook_Finish.svg") no-repeat;
}
.__icon._icon-poi-start {
  background: url("../icons/chart/nc_Picto_Roadbook_Start_green.svg") no-repeat;
}
.__icon._icon-avatar-trailer-male-1 {
  background: url("../icons/avatars/avatar-trailer-male-1.png") no-repeat;
}
.__icon._icon-avatar-trailer-male-2 {
  background: url("../icons/avatars/avatar-trailer-male-2.png") no-repeat;
}
.__icon._icon-avatar-trailer-male-3 {
  background: url("../icons/avatars/avatar-trailer-male-3.png") no-repeat;
}
.__icon._icon-avatar-trailer-male-4 {
  background: url("../icons/avatars/avatar-trailer-male-4.png") no-repeat;
}
.__icon._icon-avatar-trailer-female-1 {
  background: url("../icons/avatars/avatar-trailer-female-1.png") no-repeat;
}
.__icon._icon-avatar-trailer-female-2 {
  background: url("../icons/avatars/avatar-trailer-female-2.png") no-repeat;
}
.__icon._icon-avatar-trailer-female-3 {
  background: url("../icons/avatars/avatar-trailer-female-3.png") no-repeat;
}
.__icon._icon-avatar-trailer-female-4 {
  background: url("../icons/avatars/avatar-trailer-female-4.png") no-repeat;
}
.__icon._icon-avatar-trailer-other {
  background: url("../icons/avatars/avatar-trailer-male-1.png") no-repeat;
}
.__icon._icon-avatar-orga-male-1 {
  background: url("../icons/avatars/avatar-orga-male.png") no-repeat;
}
.__icon._icon-avatar-orga-female-1 {
  background: url("../icons/avatars/avatar-orga-female.png") no-repeat;
}
.__icon._icon-avatar-orga-other {
  background: url("../icons/avatars/avatar-orga-male.png") no-repeat;
}
.__icon._icon-utmb-index-20k {
  background: url("../icons/utmb/20k.svg") no-repeat;
  max-height: 35px;
}
.__icon._icon-utmb-index-50k {
  background: url("../icons/utmb/50k.svg") no-repeat;
  max-height: 35px;
}
.__icon._icon-utmb-index-100k {
  background: url("../icons/utmb/100k.svg") no-repeat;
  max-height: 35px;
}
.__icon._icon-utmb-index-100m {
  background: url("../icons/utmb/100m.svg") no-repeat;
  max-height: 35px;
}
.__icon._icon-running-stones {
  background: url("../icons/utmb/RS4_tmp.png") no-repeat;
}

.__avatar_event {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  z-index: 5;
  background-size: cover !important;
  background-position: center !important;
}
.__avatar_event:before {
  position: absolute;
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 2px solid #CBD4E5;
  border-radius: 50%;
  top: -5px;
  left: -5px;
  z-index: -10;
}
@media all and (max-width: 1281px) {
  .__avatar_event {
    width: 60px;
    height: 60px;
  }
}
@media all and (max-width: 992px) {
  .__avatar_event {
    width: 50px;
    height: 50px;
  }
}
.__avatar_event._avatar-sea {
  background: url("../icons/avatars/avatar-sea.png") no-repeat;
}
.__avatar_event._avatar-mountain {
  background: url("../icons/avatars/avatar-mountain.png") no-repeat;
}
.__avatar_event._avatar-city {
  background: url("../icons/avatars/avatar-city.png") no-repeat;
}
.__avatar_event._avatar-sand {
  background: url("../icons/avatars/avatar-sand.png") no-repeat;
}
.__avatar_event._avatar-country {
  background: url("../icons/avatars/avatar-country.png") no-repeat;
}