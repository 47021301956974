/* YOUR CUSTOM STYLES */
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;700&display=swap");
@font-face {
  font-family: "QuickSand";
  src: url("../font/QuickSand/Quicksand_Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "QuickSand";
  src: url("../font/QuickSand/Quicksand_Book.otf");
  font-weight: 500;
}
@font-face {
  font-family: "QuickSand";
  src: url("../font/QuickSand/Quicksand_Light.otf");
  font-weight: 300;
}
/************************ECRANS*/
/*=============================================================================
    MIXINS MEDIA QUERIES
 ============================================================================== */
/************************ECRANS*/
/*=============================================================================
    MIXINS MEDIA QUERIES
 ============================================================================== */
::ng-deep .mat-horizontal-content-container {
  padding: 0 !important;
}

.left-content {
  padding-top: 1em;
}
@media all and (max-width: 992px) {
  .left-content {
    width: 60% !important;
  }
}
@media all and (max-width: 768px) {
  .left-content {
    width: 100% !important;
    padding: 1rem 20px !important;
  }
}
@media all and (max-width: 600px) {
  .left-content._no_padding_mobile {
    padding: 2rem 0 !important;
  }
}

.right-content {
  padding-top: 1em;
}
@media all and (max-width: 992px) {
  .right-content {
    width: 40% !important;
  }
}
@media all and (max-width: 768px) {
  .right-content {
    width: 100% !important;
    padding: 1rem 20px !important;
  }
}

._bloc_alert {
  width: 90%;
}
._bloc_alert span {
  width: -moz-calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
  display: block;
}
@media all and (max-width: 1281px) {
  ._bloc_alert {
    margin: 1rem 0;
  }
}
@media all and (max-width: 768px) {
  ._bloc_alert {
    width: 95%;
  }
  ._bloc_alert._border {
    border: 2px solid #CBD4E5;
  }
}
@media all and (max-width: 768px) {
  ._bloc_alert {
    width: 100%;
  }
}

._bloc_orga {
  width: 90%;
}
@media all and (max-width: 768px) {
  ._bloc_orga {
    width: 95%;
  }
}
@media all and (max-width: 768px) {
  ._bloc_orga {
    width: 100%;
  }
}

._marge_top {
  margin-top: 2rem;
}

._poiDialog {
  width: auto;
  border-radius: 10px;
  overflow: hidden;
}
._poiDialog .mat-dialog-content {
  max-height: 90vh;
}
._poiDialog ._content_poi {
  overflow: auto;
  max-height: 80vh;
}
._poiDialog .poi-dialog-content {
  margin: 0;
  padding: 0 !important;
}

/************************ECRANS*/
/*=============================================================================
    MIXINS MEDIA QUERIES
 ============================================================================== */
.__splits_toolbar {
  padding: 50px 15px;
  background-color: white;
  border-bottom: 2px solid #EBF1FC;
  font-size: 1em;
}
@media all and (max-width: 768px) {
  .__splits_toolbar {
    display: none;
  }
}

a.a_surline {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
  font-size: 1em;
  margin: 1rem 0;
}

a._aline {
  position: relative;
  padding-bottom: 3px;
}
a._aline:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #000000;
  transform-origin: left;
  transform: scaleX(0);
  -webkit-transition: transform 0.6s ease;
  -moz-transition: transform 0.6s ease;
  -ms-transition: transform 0.6s ease;
  -o-transition: transform 0.6s ease;
  transition: transform 0.6s ease;
}
a._aline:hover:after {
  transform: scaleX(1);
}
a._aline._blue_link {
  color: #2394FC;
}
a._aline._blue_link:after {
  background: #2394FC;
}
a._aline._blue_link:hover {
  color: #2394FC;
}
a._aline._white_link {
  color: #ffffff;
}
a._aline._white_link:after {
  background: #ffffff;
}
a._aline._white_link:hover {
  color: #ffffff;
}

::ng-deep ._btn_return {
  margin: 1rem 0 0 !important;
}

._btn {
  background: none;
  border: 0;
}
._btn._right {
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
}
._btn._white {
  color: #ffffff;
}

::ng-deep ._link_red {
  color: red !important;
  text-decoration: none;
}
::ng-deep ._link_red._center {
  text-align: center;
}

._btn_fb {
  border: 2px solid #CBD4E5;
  background: #ffffff;
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}
._btn_fb .fa {
  color: #2394FC;
  margin-right: 20px;
}
._btn_fb span {
  color: #CBD4E5;
}
._btn_fb:hover {
  background: #2394FC;
  border: 2px solid #2394FC;
}
._btn_fb:hover .fa {
  color: #ffffff;
}
._btn_fb:hover span {
  color: #ffffff;
}

::ng-deep .btn-action-wrap._full {
  width: 100%;
}
::ng-deep .btn-action-wrap._full ._sp_btn {
  width: 100%;
  margin: 0 1rem;
}

::ng-deep ._sp_btn {
  border-radius: 30px;
  padding: 12px 30px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: 0;
  line-height: normal;
  display: inline-block;
  text-transform: none !important;
  margin: 0 10px;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -ms-transition: background 1s ease;
  -o-transition: background 1s ease;
  transition: background 1s ease;
}
::ng-deep ._sp_btn._circle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
::ng-deep ._sp_btn._full {
  width: 100%;
  display: block;
  margin: 0;
  padding: 15px 30px;
  border-radius: 30px;
}
::ng-deep ._sp_btn._full span {
  font-size: 1em;
}
::ng-deep ._sp_btn._full span._center {
  text-align: center;
  display: block;
}
::ng-deep ._sp_btn .mat-icon, ::ng-deep ._sp_btn i {
  font-size: 1.3em;
  position: relative;
  margin-right: 10px;
  top: 2px;
}
::ng-deep ._sp_btn.no_marge {
  margin: 0;
}
::ng-deep ._sp_btn span {
  font-size: 0.95em;
  line-height: 1em;
  font-weight: 400;
  margin: 0 !important;
}
::ng-deep ._sp_btn._search_btn span.mat-button-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::ng-deep ._sp_btn._search_btn span.mat-button-wrapper i {
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  top: -0.5px;
}
::ng-deep ._sp_btn._center {
  margin: 0 auto;
}
::ng-deep ._sp_btn._inline {
  padding: 15px 30px;
}
::ng-deep ._sp_btn._inline span {
  font-size: 0.95em;
  font-weight: 400;
}
::ng-deep ._sp_btn._middle {
  padding: 0 40px;
}
::ng-deep ._sp_btn._middle span {
  line-height: 0.95em;
  font-size: 0.9em;
  font-weight: 500;
}
::ng-deep ._sp_btn._small {
  padding: 10px 20px;
}
::ng-deep ._sp_btn._inline_icon {
  padding: 0px;
}
::ng-deep ._sp_btn._inline_icon i {
  margin-right: 0px;
}
::ng-deep ._sp_btn._blue {
  background: #2394FC;
  color: #ffffff !important;
}
::ng-deep ._sp_btn._blue:hover {
  background: #0260b7;
}
::ng-deep ._sp_btn._no_bg {
  background: none;
  color: rgba(0, 0, 0, 0.6) !important;
}
::ng-deep ._sp_btn._no_bg i {
  color: #CBD4E5;
  margin-right: 0.7rem;
}
::ng-deep ._sp_btn._red, ::ng-deep ._sp_btn._danger {
  background: #CC0000;
  color: #ffffff !important;
}
::ng-deep ._sp_btn._red:hover, ::ng-deep ._sp_btn._danger:hover {
  background: #660000;
}
::ng-deep ._sp_btn._green, ::ng-deep ._sp_btn._success {
  background: #0AD6AF;
  color: #ffffff !important;
}
::ng-deep ._sp_btn._green:hover, ::ng-deep ._sp_btn._success:hover {
  background: #05755f;
}
::ng-deep ._sp_btn._white {
  background: #ffffff;
  border: 2px solid #CBD4E5;
  color: #191C1F;
}
::ng-deep ._sp_btn._white i {
  color: #2394FC;
}
::ng-deep ._sp_btn._white span, ::ng-deep ._sp_btn._white .mat-icon {
  color: #000000;
}
::ng-deep ._sp_btn._white:hover {
  background: #EBF1FC;
}
::ng-deep ._sp_btn._no_border {
  border: 0;
}
::ng-deep ._sp_btn._white_black {
  border: 2px solid #CBD4E5;
}
::ng-deep ._sp_btn._white_black span {
  color: #000000;
}
::ng-deep ._sp_btn._white_black .mat-icon, ::ng-deep ._sp_btn._white_black i {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep ._sp_btn._white_black:hover {
  background: #EBF1FC;
}
::ng-deep ._sp_btn._white_green {
  border: 2px solid #0AD6AF;
}
::ng-deep ._sp_btn._white_green span {
  color: #000000;
}
::ng-deep ._sp_btn._white_green .mat-icon, ::ng-deep ._sp_btn._white_green i {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep ._sp_btn._white_green:hover {
  background: #EBF1FC;
}
::ng-deep ._sp_btn:active, ::ng-deep ._sp_btn:focus {
  outline: none;
}
::ng-deep ._sp_btn:disabled {
  cursor: none;
  pointer-events: none;
  background: #EBF1FC;
}
::ng-deep ._sp_btn:disabled span {
  color: #CBD4E5;
}
::ng-deep ._sp_btn:disabled:hover {
  background: #EBF1FC;
}
::ng-deep ._sp_btn:disabled:hover span {
  color: #CBD4E5;
}
@media all and (max-width: 1455px) {
  ::ng-deep ._sp_btn {
    padding: 10px 20px;
    border-radius: 20px;
  }
  ::ng-deep ._sp_btn span {
    font-size: 0.95em;
  }
  ::ng-deep ._sp_btn._inline {
    padding: 10px 20px;
  }
}
@media all and (max-width: 768px) {
  ::ng-deep ._sp_btn span {
    font-size: 0.9em;
  }
  ::ng-deep ._sp_btn._inline span {
    font-size: 0.9em;
  }
}
@media all and (max-width: 600px) {
  ::ng-deep ._sp_btn span {
    font-size: 0.85em;
  }
  ::ng-deep ._sp_btn._inline span {
    font-size: 0.85em;
  }
}

::ng-deep ._sp_btn_arrow {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  transition: transform 0.5s ease;
}
::ng-deep ._sp_btn_arrow .mat-icon {
  position: relative;
  transition: transform 0.4s ease, opacity 0.8s ease;
  margin-left: 5px;
}
::ng-deep ._sp_btn_arrow span {
  font-weight: 500;
  font-size: 1em;
}
::ng-deep ._sp_btn_arrow._right {
  float: right;
}
::ng-deep ._sp_btn_arrow:hover {
  transform: translateX(20px);
}
::ng-deep ._sp_btn_arrow:hover .mat-icon {
  transform: translateX(10px);
  opacity: 0;
}
::ng-deep ._sp_btn_arrow:hover span {
  color: #2394FC;
}
@media all and (max-width: 992px) {
  ::ng-deep ._sp_btn_arrow .mat-icon {
    font-size: 18px;
    top: 3px;
    margin-left: 10px;
  }
  ::ng-deep ._sp_btn_arrow span {
    font-size: 0.9em;
  }
}

::ng-deep .mat-icon-button {
  font-size: 20px !important;
}

.badge {
  padding: 0.5rem;
  color: #ffffff;
  font-size: 0.8rem;
}

@media all and (max-width: 768px) {
  .__stepper {
    width: 100%;
  }
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background: none;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  padding: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mat-horizontal-stepper-header-container {
  width: fit-content;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: 50px !important;
  height: 10px !important;
  border-radius: 15px !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon .mat-step-icon-content {
  display: none;
}

.mat-step-header .mat-step-icon-state-number {
  background-color: #EBF1FC;
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done {
  background-color: #2394FC;
}

.alert {
  border-radius: 10px;
  padding: 20px;
  margin: 1.3rem 0;
}
.alert.alert-success {
  background: #0AD6AF;
  color: #ffffff;
}

._card {
  border-radius: 10px;
  padding: 1rem;
  margin: 1.3rem 0;
}
._card span {
  display: block;
}
._card._border {
  border: 2px solid #CBD4E5;
}
._card._backg {
  background: #EBF1FC;
}
._card._backgreen {
  background: #0AD6AF;
  color: #ffffff;
}
@media all and (max-width: 1281px) {
  ._card {
    margin: 1rem 0;
  }
}
@media all and (max-width: 768px) {
  ._card._border {
    border: 1px solid #CBD4E5;
  }
}

._chip {
  display: inline-block;
  border: 2px solid #2394FC;
  padding: 5px 15px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  font-size: 0.8em;
  line-height: 1em;
  margin: 0 1rem;
}

.picture-card {
  margin: 15px 0;
  padding: 0 16px 0 16px !important;
}

.picture-card .btn-delete {
  position: absolute;
  top: 1rem;
  right: 1rem;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  color: white;
}

.picture-card .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  background-attachment: fixed;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.picture-card:hover .mask,
.picture-card:hover .btn-delete {
  opacity: 1;
}

._bl_input {
  position: relative;
}
._bl_input input {
  border-radius: 30px;
  background: #ffffff;
  border: 2px solid #CBD4E5;
  padding: 10px 30px 10px 50px;
  width: 100%;
  transition: transform 0.5s ease;
}
._bl_input i {
  position: absolute;
  left: 17px;
  top: 15px;
  font-size: 1.2em;
  color: #CBD4E5;
}

.mat-form-field._input_login .mat-form-field-infix {
  padding: 0.2em 0 0.5em 0;
}
.mat-form-field._input_login .mat-form-field-outline-thick {
  color: #2394FC;
}

.login-form-wrap,
.register-form-wrap {
  padding-top: 30px;
  overflow: hidden;
}

.__select {
  width: 100%;
}
.__select ._wrap_input {
  width: 100%;
  position: relative;
}
.__select .mat-form-field-infix {
  width: 100%;
  padding: 0 0 0.5rem 0 !important;
  border-top: 0 !important;
}
.__select .mat-form-field-underline {
  border: 0 !important;
  height: 0 !important;
  display: none;
}
.__select label {
  display: block;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}
.__select label._little {
  font-size: 0.9em;
  font-weight: 400;
}
.__select label.mat-radio-label {
  font-size: 0.9em;
  display: inline-flex;
}
.__select .mat-select-trigger,
.__select .mat-select-value,
.__select .mat-select-value-text {
  height: 100%;
}
.__select .mat-select-value-text {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.__select .mat-select-arrow-wrapper {
  border-left: 2px solid #CBD4E5;
  padding: 0 0.2rem;
}
.__select .__chp_select {
  border: 2px solid #CBD4E5;
  border-radius: 5px;
  height: 40px;
}

.form-group._no_marge {
  margin-bottom: 0 !important;
}

.__slider label {
  display: block;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}
.__slider .ngx-slider .ngx-slider-selection {
  background-color: #0AD6AF;
}
.__slider .ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: -9px;
  background-color: white;
  border: 1px solid #CBD4E5;
  z-index: 3;
  border-radius: 16px;
}
.__slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}
.__slider .ngx-slider .ngx-slider-bubble {
  font-size: 12px;
}
.__slider .ngx-slider .ngx-slider-bubble:not(.ngx-slider-limit) {
  color: #191C1F;
  background-color: #0ad6af;
  padding: 1px 5px;
  border-radius: 5px;
}

.__checkbox .__checkbox_icon {
  border-color: #0AD6AF;
}
.__checkbox .__checkbox_icon .__checked .__icon {
  border-color: #0AD6AF;
  -webkit-box-shadow: 0px 0px 8px 2px rgb(10, 214, 175);
  -moz-box-shadow: 0px 0px 8px 2px rgb(10, 214, 175);
  box-shadow: 0px 0px 8px 2px rgb(10, 214, 175);
}
.__checkbox label {
  display: block;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}

.__input {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.__input._marge_input {
  margin-bottom: 1.5rem !important;
}
.__input ._sep {
  margin: 0 5px;
  transform: translateY(-50%);
}
.__input ._wrap_input {
  width: 100%;
  position: relative;
}
.__input ._wrap_input._nopadding_mat-form-field-wrapper {
  margin-bottom: -1.3em;
}
.__input ._wrap_input._input_search {
  border-right: 1px solid #CBD4E5;
}
.__input ._wrap_input._input_search._no_border {
  border: 0;
}
.__input ._wrap_input._input_search label {
  display: none !important;
}
.__input ._wrap_input._input_search ._label_abs {
  top: 4px;
}
.__input ._wrap_input._input_search .mat-datepicker-toggle {
  position: relative;
  left: 3px;
}
.__input ._wrap_input._input_search .mat-datepicker-toggle svg path {
  fill: #2394FC;
}
.__input ._wrap_input._input_search .mat-form-field-flex {
  align-items: end;
}
.__input ._wrap_input._input_search .mat-form-field-infix {
  padding-bottom: 0 !important;
  top: 3px;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-container {
  position: relative;
  top: 2px;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-start-wrapper,
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-end-wrapper {
  width: 100%;
  min-width: 120px;
  height: 100%;
  display: flex;
  position: relative;
  max-width: none !important;
  align-self: end;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-start-wrapper .mat-date-range-input-inner,
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-end-wrapper .mat-date-range-input-inner {
  bottom: 0;
}
.__input ._wrap_input._input_search .mat-form-field-wrapper {
  padding-bottom: 0;
}
.__input ._wrap_input._input_search {
  border-right: 1px solid #CBD4E5;
}
.__input ._wrap_input._input_search._no_border {
  border: 0;
}
.__input ._wrap_input._input_search label {
  display: none !important;
}
.__input ._wrap_input._input_search ._label_abs {
  top: 4px;
}
.__input ._wrap_input._input_search .mat-datepicker-toggle {
  position: relative;
  left: 3px;
}
.__input ._wrap_input._input_search .mat-datepicker-toggle svg path {
  fill: #2394FC;
}
.__input ._wrap_input._input_search .mat-form-field-flex {
  align-items: end;
}
.__input ._wrap_input._input_search .mat-form-field-infix {
  padding-bottom: 0 !important;
  top: 3px;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-container {
  position: relative;
  top: 2px;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-start-wrapper,
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-end-wrapper {
  width: 100%;
  min-width: 120px;
  height: 100%;
  display: flex;
  position: relative;
  max-width: none !important;
  align-self: end;
}
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-start-wrapper .mat-date-range-input-inner,
.__input ._wrap_input._input_search .mat-date-range-input .mat-date-range-input-end-wrapper .mat-date-range-input-inner {
  bottom: 0;
}
.__input ._wrap_input.mat-form-field-should-float ._label_abs span {
  display: none;
}
.__input ._wrap_input .mat-form-field-infix {
  width: 100%;
  padding: 0 0 0.5rem 0 !important;
  border-top: 0 !important;
}
.__input ._wrap_input._small .mat-form-field-infix {
  padding-bottom: 0 !important;
}
.__input .__icon_input {
  position: relative;
}
.__input .__icon_input .mat-datepicker-toggle {
  position: absolute;
  right: 0;
  top: 0;
  border-left: 2px solid #CBD4E5;
}
.__input .mat-form-field-underline {
  display: none;
}
.__input label {
  display: block;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}
.__input label._little {
  font-size: 0.85em;
  font-weight: 400;
  margin-top: 0.5rem;
}
.__input label.mat-radio-label {
  font-size: 0.9em;
  display: inline-flex;
}
.__input ._label_abs {
  position: absolute;
  transform-origin: left;
}
.__input ._label_abs i {
  margin-right: 10px;
  color: #2394FC;
}
.__input ._label_abs span {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
}
.__input input {
  box-sizing: border-box;
  caret-color: #CBD4E5;
  border: 2px solid #CBD4E5;
  padding: 10px;
  width: 100%;
  display: block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.__input input._line {
  border: 0;
  padding: 5px 0 5px 20px;
  border-radius: 0;
}
.__input textarea {
  width: -moz-calc(100% - 3rem) !important;
  width: -webkit-calc(100% - 3rem) !important;
  width: calc(100% - 3rem) !important;
  border: 2px solid #CBD4E5;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 1rem !important;
}

._alternate {
  text-align: center;
  position: relative;
  height: 1px;
  background: #CBD4E5;
  width: 100%;
  margin: 2rem 0 1rem 0;
}
._alternate strong {
  padding: 3px 10px;
  background: #ffffff;
  color: #CBD4E5;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  transform: translateY(-50%);
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-datepicker-toggle-active {
  color: #2394FC;
}

.mat-button-focus-overlay {
  display: none;
}

.__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  left: -moz-calc(50% - 60px);
  left: -webkit-calc(50% - 60px);
  left: calc(50% - 60px);
  top: -60px;
  background: #CBD4E5;
}
.__avatar._profil {
  background: #ffffff;
}
.__avatar img {
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.__avatar._inline {
  position: relative;
  width: 70px;
  height: 70px;
  left: 1rem;
  margin-top: 1rem;
  top: 0;
}
@media all and (max-width: 1281px) {
  .__avatar {
    width: 100px;
    height: 100px;
    left: -moz-calc(50% - 50px);
    left: -webkit-calc(50% - 50px);
    left: calc(50% - 50px);
    top: -50px;
  }
}
@media all and (max-width: 768px) {
  .__avatar {
    width: 70px;
    height: 70px;
    left: -moz-calc(50% - 35px);
    left: -webkit-calc(50% - 35px);
    left: calc(50% - 35px);
    top: -35px;
  }
}

.organizer_card,
.event_sum_up_card {
  border: 2px solid #CBD4E5;
  box-shadow: none !important;
  padding: 60px 30px 20px !important;
  border-radius: 10px !important;
  text-align: center;
  position: relative;
}
.organizer_card._over_hidden,
.event_sum_up_card._over_hidden {
  overflow: hidden;
}
.organizer_card .__location,
.event_sum_up_card .__location {
  font-size: 0.85em;
}
.organizer_card .__location svg,
.event_sum_up_card .__location svg {
  top: 3px;
}
.organizer_card .__location span,
.event_sum_up_card .__location span {
  display: inline;
}
.organizer_card .member_date,
.event_sum_up_card .member_date {
  font-size: 0.9em;
  display: block;
}
.organizer_card._no_padding,
.event_sum_up_card._no_padding {
  padding: 0 !important;
}
.organizer_card h4,
.event_sum_up_card h4 {
  font-size: 1.5em;
}
.organizer_card h5,
.event_sum_up_card h5 {
  font-size: 1.2em;
  margin-bottom: 0;
}
.organizer_card span,
.event_sum_up_card span {
  font-weight: 500;
  font-size: 1.2em;
  display: block;
}
.organizer_card ._badge,
.event_sum_up_card ._badge {
  padding: 5px 10px;
  color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  font-size: 0.8em;
}
.organizer_card ._location,
.event_sum_up_card ._location {
  margin: 1rem 0;
  display: block;
}
.organizer_card ._location .mat-icon,
.event_sum_up_card ._location .mat-icon {
  color: #CBD4E5;
  position: relative;
  font-size: 20px;
  top: 2px;
}
.organizer_card ._location span,
.event_sum_up_card ._location span {
  color: rgba(0, 0, 0, 0.6);
}
.organizer_card ._btn_action,
.event_sum_up_card ._btn_action {
  padding-bottom: 1rem;
  border-top: 2px solid #cbd4e5;
}
.organizer_card._card_race,
.event_sum_up_card._card_race {
  padding: 30px !important;
}
.organizer_card._card_race h5,
.event_sum_up_card._card_race h5 {
  font-size: 0.9em;
  display: block;
}
.organizer_card._card_race span,
.event_sum_up_card._card_race span {
  font-size: 1.2em;
  display: block;
}
@media all and (max-width: 1281px) {
  .organizer_card,
  .event_sum_up_card {
    padding: 70px 20px 20px !important;
  }
  .organizer_card h4,
  .event_sum_up_card h4 {
    font-size: 1.3em;
  }
  .organizer_card span,
  .event_sum_up_card span {
    font-size: 1em;
  }
  .organizer_card .location-wrap span,
  .event_sum_up_card .location-wrap span {
    font-size: 0.9em;
  }
}
@media all and (max-width: 992px) {
  .organizer_card h4,
  .event_sum_up_card h4 {
    font-size: 1.2em;
  }
}
@media all and (max-width: 768px) {
  .organizer_card,
  .event_sum_up_card {
    padding: 50px 20px 20px !important;
  }
  .organizer_card .location-wrap span,
  .event_sum_up_card .location-wrap span {
    font-size: 0.8em;
  }
}
.organizer_card ._orga_socials,
.event_sum_up_card ._orga_socials {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  margin: 1.5rem 0 0;
}
.organizer_card ._orga_socials li,
.event_sum_up_card ._orga_socials li {
  display: inline-block;
  margin: 0 10px;
  list-style-type: none;
}
.organizer_card ._orga_socials li i,
.event_sum_up_card ._orga_socials li i {
  font-size: 2em;
}
.organizer_card ._orga_socials li._fb a,
.event_sum_up_card ._orga_socials li._fb a {
  color: #3b5998;
}
.organizer_card ._orga_socials li._insta a,
.event_sum_up_card ._orga_socials li._insta a {
  color: #E1306C;
}

@media all and (max-width: 600px) {
  .event-maps-counter-wrap {
    display: none;
  }
}

.event_sum_up_card {
  padding: 25px 16px !important;
}

/* CSS Document */
html {
  width: 100%;
  height: 100%;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: "Lexend", sans-serif, "Roboto", "Open Sans", "Arial Unicode MS";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.57142857;
  opacity: 1;
  margin: 0;
}
body.no-scroll {
  overflow-y: hidden;
}

body .text-wrap {
  white-space: normal;
}

button:focus,
button:active,
a:focus,
a:active {
  outline: 0;
}

/* Change the white to any color ;) */
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

img.logo {
  width: 135px;
}

._full_height {
  min-height: 100vh;
}

a:hover,
.hover-pointer {
  cursor: pointer;
  text-decoration: none;
}

form label {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}

.mat-ink-bar {
  background-color: #2394FC !important;
  height: 4px !important;
}

.mat-tab-body-content {
  height: inherit !important;
  margin: 0 auto 1.5rem auto !important;
}

.mat-tab-label {
  font-size: 1em !important;
  color: #191C1F !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.mat-tab-label.mat-tab-label-active {
  color: #2394FC !important;
}

.mat-tab-body-wrapper {
  border-top: 1px solid #EBF1FC;
}

.mat-tab-header {
  margin: 1px auto;
  border-bottom: none !important;
}

.mat-tab-body-content {
  margin: 1.5rem auto 5rem auto;
}

.mat-tab-links {
  text-align: center !important;
}

.mat-tab-link {
  font-size: 0.8rem !important;
  color: #240457 !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.mat-raised-button,
.mat-button {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.mat-raised-button:hover, .mat-raised-button:focus,
.mat-mini-fab:hover,
.mat-mini-fab:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.mat-raised-button.rounded-button,
.mat-button.rounded-button {
  border-radius: 25px !important;
  font-size: 0.8rem !important;
  line-height: 26px !important;
}

.mat-sidenav-container {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.mat-mini-fab .mat-icon,
.mat-mini-fab i {
  padding: 0px !important;
  display: block !important;
}

.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  color: white;
}

.bordered-button {
  border: 1px solid !important;
}

.mat-menu-content .mat-menu-item i {
  margin: 0 0.5rem 0 0;
}

.bg-light-grey {
  background-color: #EBF1FC;
}

.bg-primary {
  background-color: #2394FC !important;
}

.bg-secondary {
  background-color: #0AD6AF !important;
}

.white {
  color: white;
}

.default {
  color: rgba(0, 0, 0, 0.6) !important;
}

.primary {
  color: #2394FC !important;
}

.warn {
  color: #FF8800 !important;
}

.accent {
  color: #2394FC !important;
}

.bg-default {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.bg-info {
  background-color: #2394FC !important;
}

.bg-warning {
  background-color: #FF8800 !important;
}

.bg-danger {
  background-color: #CC0000 !important;
}

.bg-success {
  background-color: #0AD6AF !important;
}

.text-default {
  color: #0AD6AF !important;
}

.text-info {
  color: #2394FC !important;
}

.text-warning {
  color: #FF8800 !important;
}

.text-danger {
  color: #CC0000 !important;
}

.text-success {
  color: #0AD6AF !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.init-app-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: #EBF1FC;
}

html, body {
  height: 100%;
  min-height: 100vh;
}

@media all and (max-width: 768px) {
  ._router_top {
    padding-top: 0;
  }
}

ul._no_bullet li {
  list-style-type: none;
}
ul._bullet_check {
  padding: 0;
}
ul._bullet_check li {
  list-style-type: none;
  position: relative;
  padding-left: 2.5em;
  margin-bottom: 1em;
}
ul._bullet_check li:before {
  font-family: "Material Icons";
  position: absolute;
  top: -3px;
  left: 0;
  content: "check";
  color: #2394FC;
  font-size: 1.5em;
}

._70 {
  width: 70%;
}
@media all and (max-width: 1281px) {
  ._70 {
    width: 80%;
  }
}
@media all and (max-width: 992px) {
  ._70 {
    width: 100%;
  }
}

._80 {
  width: 80%;
}
@media all and (max-width: 992px) {
  ._80 {
    width: 100%;
  }
}

._container_root {
  padding-top: 70px;
  overflow: hidden;
  min-height: 100%;
}

.grecaptcha-badge {
  bottom: 0 !important;
  right: -300px !important;
}